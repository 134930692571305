var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "d2-source",
          class: { "d2-source--active": _vm.isActive },
          on: { click: _vm.handleClick },
        },
        [_c("d2-icon", { attrs: { name: "code" } }), _vm._v(" 本页源码 ")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }