import store from "@/store";
import util from "@/d2admin/libs/util";
import { Message } from "element-ui";
import { mapState, mapActions } from "vuex";
import logout from "@/d2admin/store/modules/account";
import router from "@/router";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { delegate } from "@/d2admin/delegate/index";
import AxiosDelegate = delegate.AxiosDelegate;

// 创建一个错误
export function errorCreate(msg: string) {
  const error = new Error(msg);
  errorLog(error);
  throw error;
}
export function errorLog(error: Error, toast: boolean = true) {
  // 添加到日志
  store.dispatch("d2admin/log/push", {
    message: "数据请求异常",
    type: "danger",
    meta: {
      error,
    },
  });
  // 打印到控制台
  if (process.env.NODE_ENV === "development") {
    util.log.danger(">>>>>> Error >>>>>>");
    console.log(error);
  }
  // 显示提示
  if (toast) {
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
  }
}

export function translateHttpStatus(error: AxiosError): string {
  if (!error || !error.response) return "未知错误";
  switch (error.response.status) {
    case 400:
      return "请求错误";
    case 401:
      return "登录超时，重新登录";
    case 403:
      return "拒绝访问";
    case 404:
      return `请求地址出错: ${error.response.config.url}`;
    case 408:
      return "请求超时";
    case 500:
      return "服务器内部错误";
    case 501:
      return "服务未实现";
    case 502:
      return "网关错误";
    case 503:
      return "服务不可用";
    case 504:
      return "网关超时";
    case 505:
      return "HTTP版本不受支持";
  }
  return "未知错误";
}

export class AxiosDelegateDefault implements AxiosDelegate {
  beforeRequest(
    config: AxiosRequestConfig
  ): AxiosRequestConfig | Promise<AxiosRequestConfig> {
    // 在请求发送之前做一些处理
    const token = util.cookies.get("token");
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    if (util.cookies.get("token")) {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    config.headers["tenant_id"] = '10';
    return config;
  }

  onRequestError(error: AxiosError): void {
    console.log(error);
  }

  beforeResponse(response: AxiosResponse): any {
    if (
      response.status === 401 ||
      response.data.code === 401 ||
      response.data.code === 1001 ||
      response.data.code === 1000
    ) {
      router.push(`/login`);
      util.cookies.remove("token");
      return Promise.reject(new Error("登录超时，重新登录"));
    }
    // dataAxios 是 axios 返回数据中的 data
    return response;
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const { code } = dataAxios;
    console.log(dataAxios);
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 0:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios;
        case "xxx":
          // [ 示例 ] 其它和后台约定的 code
          errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
          break;
        default:
          // 不是正确的 code
          errorCreate(`${dataAxios.msg}: ${response.config.url}`);
          break;
      }
    }
  }
  onResponseError(error: AxiosError): any {
    if (error.response?.status === 401) {
      store.dispatch("user/logout");
      router.push(`/login`);
      // clearLoginInfo()
      return Promise.reject(error.response.data.msg);
    }
    error.message = translateHttpStatus(error);
    errorLog(error);
  }
}

let INSTANCE = new AxiosDelegateDefault();
export default {
  get() {
    return INSTANCE;
  },
  set(delegate: AxiosDelegate) {
    INSTANCE = delegate;
  },
};
