var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("p", { staticClass: "page_title" }, [_vm._v("404 page not found")]),
      _c(
        "el-button",
        {
          staticClass: "d2-mt",
          on: {
            click: function ($event) {
              return _vm.$router.replace({ path: "/" })
            },
          },
        },
        [_vm._v(" 返回首页 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }