import http from '@/d2admin/plugin/axios'
const state = {
  industrys: [
    {
      industryId: '',
      industryName: '全部'
    }
  ]
}

const mutations = {
  COMMIT_INDUSTRYS: (state, data) => {
    state.industrys = []
    state.industrys = [...state.industrys, ...data]
    state.industrys.unshift({
      industryId: '',
      industryName: '全部'
    })
  }
}

const actions = {
  // 获取行业
  getIndustrys({ commit }) {
    return http.get(`/console/industry/industrys`).then(({ data: res }) => {
      commit('COMMIT_INDUSTRYS', res.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
