<template>
<el-dialog :visible.sync="diastatus" width="750px" :close-on-click-modal="false" :before-close="handleClose">
  <div class="la-dialog">
    <div class="dialog-title">{{titletype ==='forgetpassword'? '忘记密码':'修改密码'}}</div>
    <div class="dialog-breif" v-if="titletype ==='inittype'">为了账号安全，首次登录系统必须修改密码</div>
    <div class="dialog-breif" v-if="titletype ==='timeOut'">为了账号安全，90天之内至少修改一次密码</div>
    <div class="dialog-breif" v-if="titletype ==='forgetpassword'">密码找回后，需要重新登录</div>
    <div class="dialog-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width='auto' style="width:90%;margin: 0 auto" class="demo-ruleForm">
          <el-form-item label="机构ID" prop="organ" v-if="titletype ==='forgetpassword'">
            <div class="changepassword-input-container">
              <el-input v-model.number="ruleForm.organ" placeholder="请输入机构ID" type="text" ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="机构ID" v-else>
            <div >
              <span >{{ruleForm.organ}}</span>
            </div>
          </el-form-item>
          <el-form-item label="绑定手机号" prop="phone" v-if="titletype ==='forgetpassword'">
            <div class="changepassword-input-container">
              <el-input  v-model="ruleForm.phone" maxlength='11' placeholder="请输入绑定手机号" type="text" ></el-input>
              <div class="getCode">
                <el-button icon="el-icon-mobile-phone" @click="sendMessage" size="small" type="primary" :disabled="disabled=!show" >
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{count}} s</span>
                </el-button>
            </div>
            </div>
          </el-form-item>
          <el-form-item label="绑定手机号"  v-else>
            <div >
                <span>{{ruleForm.phone}}</span>
                <div class="getCode">
                <el-button icon="el-icon-mobile-phone" @click="sendMessage" size="small" type="primary" :disabled="disabled=!show" >
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{count}} s</span>
                </el-button>
            </div>
              </div>
          </el-form-item>
          <el-form-item label="验证码" prop="phoneCode">
            <div class="changepassword-input-container" >
              <el-input v-model="ruleForm.phoneCode" placeholder="请输入验证码"  type="text" ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="新密码" prop="password" >
            <div class="changepassword-input-container" >
              <el-input v-model="ruleForm.password" placeholder="请输入新密码"  type="password" ></el-input>
            </div>
            <span class="form-tip" :style="{color:(this.expassword ? '#F56C6C':'rgba(0, 0, 0, 0.25)')}" ><span>{{this.expassword ? ruleForm.password=== ''? '新密码不可为空':'新密码至少包含数字、字母、符号2种组合，长度不低于8位':'新密码至少包含数字、字母、符号2种组合，长度不低于8位'}}</span></span>
          </el-form-item>
          <el-form-item label="确认密码" prop="repassword">
            <div class="changepassword-input-container" >
              <el-input v-model="ruleForm.repassword" placeholder="请输入确认密码"  type="password" ></el-input>
            </div>
          </el-form-item>
        </el-form>
    </div>
    <div class="dialog-btns" style="margin-right: 5%;">
        <el-button  @click="resetFrom('ruleForm')">取消</el-button>
        <el-button type="primary" @click="changeFromSubmit('ruleForm')">确定</el-button>
    </div>
  </div>
</el-dialog>
</template>

<script>
import Cookies from 'js-cookie'
// import { encryPwd } from '@/utils'
import CryptoJS from "crypto-js";
import util from '@/d2admin/libs/util.js'
import './contentbase.scss'
export default {
  name: 'changeForgetPasswordsetting',
  data () {
    var checkOrgan = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('机构ID不能为空'))
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'))
      }
      callback()
    }
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      if (!/^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/.test(value)) {
        callback(new Error('手机号不正确'))
      }
      callback()
    }
    var checkPhoneCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('验证码不能为空'))
      }
      callback()
    }
    var checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(' '))
        this.expassword = true
      } else {
        var pattern = /^[^\u4e00-\u9fa5]{0,}$/
        var str = value
        if (!pattern.test(str)) {
          this.expassword = true
          return
        }
        // var reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}$/
        var reg = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}$/
        var reg2 = /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,30}$/
        var reg3 = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}$/
        if (reg.test(value)) {
          this.expassword = false
          // 数字 字母
        } else if (reg2.test(value)) {
          this.expassword = false
          // 数字 符号
        } else if (reg3.test(value)) {
          this.expassword = false
          // 字母 符号
        } else {
          callback(new Error(' '))
          this.expassword = true
        }
        callback()
      }
    }
    var checkRepassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不能为空'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      diastatus: true,
      show: true,
      count: '',
      timer: null,
      itemtype: '',
      expassword: false,
      titletype: 'inittype',
      ruleForm: {
        organ: '',
        phone: '',
        phoneCode: '',
        password: '',
        repassword: '',
        authCode: ''
      },
      sendForm: {},
      rules: {
        organ: [
          { required: true, validator: checkOrgan, trigger: 'change' }
        ],
        phone: [
          { required: true, validator: checkPhone, trigger: 'change' }
        ],
        phoneCode: [
          { required: true, validator: checkPhoneCode, trigger: 'change' }
        ],
        password: [
          { required: true, validator: checkPassword, trigger: 'change' }
        ],
        repassword: [
          { required: true, validator: checkRepassword, trigger: 'change' }
        ]
      }
    }
  },
  props: {
  },
  methods: {
    init () {
      this.diastatus = true
    },
    handleClose () {
      this.$refs['ruleForm'].resetFields()
      this.diastatus = false
      this.expassword = false
      this.$parent.logins(true)
    },
    sendMessage () {
      // if (this.ruleForm.organ === '') return this.$message.error('机构ID不可为空')
      // if (this.ruleForm.phone === '') return this.$message.error('请填写手机号')
      this.$http.get('/admin/mobile/web?authCode=' + this.sendForm.authCode + '&tenant_id=' + this.sendForm.tenant_id, {
        headers: {
          isToken: false,
          'Authorization': 'Basic d2ViOnl1bnNoaS13ZWItMTIzNDU2'
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('验证码已发出，请注意查收')
          const TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.show = false
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        } else {
          return this.$message.error(res.data.message)
        }
      })
    },
    changeFromSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.diastatus = false
          var sendForms = this.sendForm
          sendForms.code = this.ruleForm.phoneCode
          sendForms.newPassword = this.encryPwd(this.ruleForm.repassword)
          this.$http.post('/auth/oauth/token', null, {
            params: sendForms,
            headers: {
              isToken: false,
              'tenant_id': 1,
              'Authorization': 'Basic d2ViOnl1bnNoaS13ZWItMTIzNDU2'
            } }).then(({ data: response }) => {
            if (!response.access_token) {
              this.$message.error(response.message)
            } else {
              this.$message.success('密码修改成功，即将自动登录，请勿离开')
              setTimeout(() => {
                //  存储token
                util.cookies.set('token', response.access_token)
                // 页面显示当前用户名称
                util.cookies.set('uuid', response.username)
                // 跳转
                this.$router.replace(this.$route.query.redirect || '/')
              }, 500)
            }
          })
        }
      })
    },
    resetFrom (formName) {
      this.diastatus = false
      this.expassword = false
      this.$parent.logins(true)
      this.$refs[formName].resetFields()
    },
    encryPwd(pwd) {
        var key = CryptoJS.enc.Latin1.parse("abcdefgh12345678");
        var iv = key;
        var encrypted = CryptoJS.AES.encrypt(pwd, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        var newPass = encrypted.toString();
        // var src = CryptoJS.enc.Utf8.parse(newPass)
        // var base64string = CryptoJS.enc.Base64.stringify(src)
        // console.log(base64string)
        return newPass;
    }
  }
}
</script>
<style lang="scss">
.dialog-content{
  .changepassword-input-container{
      height: 40px;
      display: flex;
      align-items: flex-end;
      border-bottom: solid 2px #0091FF;
      .el-input__inner{
        border: none;
        padding: 0
      }
  }
  .form-tip{
    font-weight:400;
    color:rgba(0,0,0,0.25);
    font-size:12px;
    position: absolute;
    margin-top: -10px;
    .el-icon-info{
      color: orange;
      margin-right: 5px;
      font-size: 16px;
    }
  }
}
.dialog-breif{
    color:rgba(0,0,0,0.45);
    font-size: 12px;
    font-family:PingFangSC-Regular,PingFang SC;
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.getCode{
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  top: -2px;
//   margin-top: -10px;
//   z-index: 11;
}
.el-form-item__error{
    margin-left: 0%
}
.el-message-box{
    padding-bottom: 22px;
}
.el-message-box__header{
    padding: 40px 15px 20px;
}
.el-message-box__title{
font-size:20px;
font-family:PingFangSC-Semibold,PingFangSC;
font-weight:600;
color:rgba(0,0,0,1);
}
.el-message-box__message{
    font-size:14px;
    font-family:PingFangSC-Regular,PingFangSC;
    font-weight:400;
    color:rgba(109,114,120,1);
    
}
.el-button--primary{
    background: #0091FF;
    color: #F2F2F2;
}
.el-message-box__content{
    .el-icon-warning{
        display:none;
    }
}
.el-message-box__status+.el-message-box__message{
    width: 104%;
    padding-left: 0px;
}
.el-dialog__header{
    height: 0px;
    padding: 0px;
  }
  .el-dialog__body{
      padding:20px;
  }
  .el-dialog__close{
      font-size: 20px;
      font-weight: bold;
  }
  .el-time-panel__content::after, .el-time-panel__content::before{
    margin-top:-8px;
  }

  .cke_editable{
      img{
          height: auto !important;
          width: 100% !important;
      }
  }
// .aui-content__wrapper{
//     min-height: calc(100vh - 30px);
// }
// .aui-content{
//     min-height: calc(100vh - 30px);
// }
html{
    background-color:rgba(243,247,253,1);
}


</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/views/system/login/change-forget-passwordsetting.vue"
}
</vue-filename-injector>
