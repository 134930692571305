export default [
  {
    //组织管理2.0
    path: "/OM",
    name: "om",
    meta: {
      title: "组织管理2",
      auth: true,
    },
    component: () => import("@/module/demo/views/CM/organizationalManagement"),
  },
  {
    //组织详情
    path: "channeldetial3",
    name: "channeldetial3",
    meta: {
      title: "组织创建",
      auth: true,
    },
    component: () =>
      import("@/module/demo/views/CM/organizationalManagement/channeldetial3"),
  },
  {
    //组织创建
    path: "channelCreate",
    name: "channelCreate",
    meta: {
      title: "组织创建",
      auth: true,
    },
    component: () =>
      import(
        "@/module/demo/views/CM/organizationalManagement/channeldetial3/components/organizationalCreate"
      ),
  },
  {
    //组织认证申请
    path: "organizationapply",
    name: "organizationapply",
    meta: {
      title: "机构认证申请",
      auth: true,
    },
    component: () =>
      import(
        "@/module/demo/views/CM/organizationalManagement/organizationapply"
      ),
  },
  {
    path: "/channel-list",
    name: "page1",
    meta: {
      title: "频道管理",
      auth: true,
    },
    component: () => import("@/module/demo/views/channel-list"),
  },
  {
    path: "/CM",
    name: "page1",
    meta: {
      title: "机构管理",
      auth: true,
    },
    component: () => import("@/module/demo/views/CM"),
  },
  {
    path: "channeldetial2",
    name: "channeldetial2",
    meta: {
      title: "频道信息",
      auth: true,
    },
    component: () => import("@/module/demo/views/CM/channeldetial2.vue"),
  },
  {
    path: "operate-tools",
    name: "page2",
    meta: {
      title: "运营工具",
      auth: true,
    },
    component: () => import("./views/operate-tools"),
  },
  // 频道信息
  {
    path: "channeldetial",
    name: "channeldetial",
    meta: {
      title: "频道信息",
      auth: true,
    },
    component: () => import("./views/channeldetial/index.vue"),
  },
  // {
  //   path: 'index4',
  //   name: 'page3',
  //   meta: {
  //     title: '功能插件',
  //     auth: true
  //   },
  //   component: () => import('./views/page3')
  // },
  {
    path: "plugin-detial-list",
    name: "page3",
    meta: {
      title: "功能插件",
      auth: true,
    },
    component: () => import("./views/plugin-detial-list"),
  },
  {
    path: "grid-management",
    name: "grid-management",
    meta: {
      title: "融媒大脑",
      auth: true,
    },
    redirect: "grid-management/grid-management-media",
    component: () => import("./views/grid-management"),
    children: [
      {
        path: "grid-management-grid",
        name: "grid-management-grid",
        component: () => import("./views/grid-management/grid/index.vue"),
      },
      {
        path: "grid-management-node",
        name: "grid-management-node",
        component: () => import("./views/grid-management/node/index.vue"),
      },
      {
        path: "grid-management-media",
        name: "grid-management-media",
        component: () => import("./views/grid-management/media/index.vue"),
      },
      {
        path: "grid-management-media-pending",
        name: "grid-management-media-pending",
        component: () =>
          import("./views/grid-management/media/media-pending.vue"),
      },
      {
        path: "grid-management-third",
        name: "grid-management-third",
        component: () => import("./views/grid-management/thrid/index.vue"),
      },
      {
        path: "grid-management-third-manage",
        name: "grid-management-third-manage",
        component: () =>
          import("./views/grid-management/thrid/third-manage.vue"),
      },
    ],
  },
];
