var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.diastatus, width: "450px" },
      on: {
        "update:visible": function ($event) {
          _vm.diastatus = $event
        },
      },
    },
    [
      _c("div", { staticClass: "la-dialog" }, [
        _c("div", { staticClass: "top" }, [_vm._v("客服电话")]),
        _c("div", { staticClass: "phonenum" }, [_vm._v("15566874621")]),
      ]),
      _c(
        "div",
        { staticClass: "dialog-btns" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.diastatus = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }