var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("d2-container", _vm._b({}, "d2-container", _vm.$attrs, false), [
    _c("iframe", {
      staticClass: "d2-container-frame",
      attrs: { src: _vm.src, frameborder: "0" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }