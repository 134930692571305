<template>
  <d2-container class="page">
    <div style="margin: 20px 20%">
      <el-row :gutter="20"><el-col :span="24">LAPUTA 整体增长</el-col></el-row>
      <el-row :gutter="20">
        <el-col class="grid-content" :span="11">
          <div style="padding: 30px">
            <div class="grid-content-top" style="background-color: #0091fe; margin-top:5px"><img src="../../../../module/demo/img/组 3.png" width="100%" alt=""></div>
            <div style="display: inline-block;">
              <div>
                <span style="color: #333333">频道曝光次数/人气值</span>
              </div>
              <countTo :startVal='0' class="grid-content-num" :endVal='this.consoleOverAllVO.popularityAllBefore' :duration='3000'/>
              <div>
                <span style="color: #a6a6bf;margin-top:10px;font-size: 14px">近7日新增<span :style="{color:(this.consoleOverAllVO.popularityAllBefore===0?'#ff0000':'#00cc0b')}" style="margin-left:5px">{{this.consoleOverAllVO.popularity7Before}}</span></span>
              </div>
            </div>
          </div>
          </el-col>
        <el-col class="grid-content" :span="11">
          <div style="padding: 30px">
            <div class="grid-content-top" style="background-color: #00cc00; margin-top:5px"><img src="../../../../module/demo/img/组 4.png" width="100%" alt=""></div>
            <div style="display: inline-block;">
              <div>
                <span style="color: #333333">频道用户数/独立用户</span>
              </div>
              <countTo :startVal='0' class="grid-content-num" :endVal='this.consoleOverAllVO.membersAllBefore' :duration='3000'/>
              <div>
                <span style="color: #a6a6bf;margin-top:10px;font-size: 14px">近7日新增<span :style="{color:(this.consoleOverAllVO.membersAllBefore===0?'#ff0000':'#00cc0b')}" style="margin-left:5px">{{this.consoleOverAllVO.members7Before}}</span></span>
              </div>
            </div>
          </div>
          </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col class="grid-content" :span="11">
          <div style="padding: 30px">
            <div class="grid-content-top" style="background-color: #ff9900; margin-top:5px"><img src="../../../../module/demo/img/组 5.png" width="100%" alt=""></div>
            <div style="display: inline-block;">
              <div>
                <span style="color: #333333">新闻浏览数</span>
              </div>
              <countTo :startVal='0' class="grid-content-num" :endVal='this.consoleOverAllVO.readingAllBefore' :duration='3000'/>
              <div>
                <span style="color: #a6a6bf;margin-top:10px;font-size: 14px">近7日新增<span :style="{color:(this.consoleOverAllVO.readingAllBefore===0?'#ff0000':'#00cc0b')}" style="margin-left:5px">{{this.consoleOverAllVO.reading7Before}}</span></span>
              </div>
            </div>
          </div>
          </el-col>
        <el-col class="grid-content" :span="11">
          <div style="padding: 30px">
            <div class="grid-content-top" style=" margin-top:5px"><img src="../../../../module/demo/img/组 6.png" width="100%" alt=""></div>
            <div style="display: inline-block;">
              <div>
                <span style="color: #333333">新闻发稿数</span>
              </div>
              <countTo :startVal='0' class="grid-content-num" :endVal='this.consoleOverAllVO.worksAllBefore' :duration='3000'/>
              <div>
                <span style="color: #a6a6bf;margin-top:10px;font-size: 14px">近7日新增<span :style="{color:(this.consoleOverAllVO.worksAllBefore===0?'#ff0000':'#00cc0b')}" style="margin-left:5px">{{this.consoleOverAllVO.works7Before}}</span></span>
              </div>
            </div>
          </div>
          </el-col>
      </el-row>
    </div>
    <div  class="column-container">
      <el-row :gutter="20"><el-col :span="24">LAPUTA 频道增长</el-col></el-row>
      <el-table
              style="margin-top:10px;"
              :data="this.consoleOverAllVO.channelOverList"
              :default-sort = "{prop: 'popularityAllBefore'}"
      >
        <el-table-column
                label=""
                width="220"
                min-width="140">
          <template slot-scope="scope">
            <div class="column-container-scope">{{ scope.row.channelName }}</div>
          </template>
        </el-table-column>
        <el-table-column
                prop="popularityAllBefore"
                label="频道曝光次数/人气值"
                cell-class-name='cell-title'
                sortable
                width="220"
                min-width="140">
          <template slot-scope="scope">
            <div style="font-size:14px;font-weight: bold;"><span>{{ scope.row.popularityAllBefore }}</span></div>
            <div style="font-size:12px;color: #a6a6bf"><span>近7日 +{{ scope.row.popularity7Before }}</span></div>
          </template>
        </el-table-column>
        <el-table-column
                label="频道用户数/独立用户"
                prop="membersAllBefore"
                sortable
                width="220"
                min-width="140">
          <template slot-scope="scope">
            <div style="font-size:14px;font-weight: bold;">{{ scope.row.membersAllBefore }}</div>
            <div style="font-size:12px;color: #a6a6bf">+{{ scope.row.members7Before }}</div>
          </template>
        </el-table-column>
        <el-table-column
                prop="readingAllBefore"
                label="新闻浏览数"
                sortable
                :formatter="formatter"
                width="220"
                min-width="140">
          <template slot-scope="scope">
            <div style="font-size:14px;font-weight: bold;">{{ scope.row.readingAllBefore }}</div>
            <div style="font-size:12px;color: #a6a6bf">+{{ scope.row.reading7Before }}</div>
          </template>
        </el-table-column>
        <el-table-column
                prop="worksAllBefore"
                label="新闻发稿数"
                sortable
                width="272"
                min-width="140"
                :formatter="formatter">
          <template slot-scope="scope">
            <div style="font-size:14px;font-weight: bold;">{{ scope.row.worksAllBefore }}</div>
            <div style="font-size:12px;color: #a6a6bf">+{{ scope.row.works7Before }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </d2-container>
</template>

<script>
/* eslint-disable */
import countTo from 'vue-count-to';
import util from '@/d2admin/libs/util.js'
export default {
  components: { countTo },
  data () {
      return {
      consoleOverAllVO: {
        popularityAllBefore: 100,
        popularity7Before: 10,
        membersAllBefore: 200,
        members7Before: 20,
        readingAllBefore: 300,
        reading7Before: 30,
        worksAllBefore: 400,
        works7Before: 40,
        channelOverList: [{
          channelId: "0",
          channelName: "空中花园",
          popularityAllBefore: 0,
          popularity7Before: 0,
          membersAllBefore: 0,
          members7Before: 0,
          readingAllBefore: 0,
          reading7Before: 0,
          worksAllBefore: 0,
          works7Before: 0
        },
        {
          channelId: "1",
          channelName: "云视中国",
          popularityAllBefore: 0,
          popularity7Before: 0,
          membersAllBefore: 44,
          members7Before: 0,
          readingAllBefore: 489,
          reading7Before: 0,
          worksAllBefore: 0,
          works7Before: 0
        }]
      }
    }
  },
  mounted: function (){
    this.getInitData()
  },
  methods: {
    getInitData() {
      console.log('进入初始化')
      this.$http.get(`/console/index/getOverAll`).then(({ data: res }) => {
          this.consoleOverAllVO = res.data
      })
    },
    formatter(row, column) {
      return row.address;
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table th>.cell{
  font-size: 20px;
}
.column-container{
  margin: 20px 20%;
  .column-container-scope{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    text-align: center
  }
}
.grid-content{
  height: 160px;
  background-color: white;
  margin: 10px;
  padding: 10px 50px;
  position: relative;
  .grid-content-top{
    width: 30px;
    height: 30px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px;
    vertical-align: top;
  }
  .grid-content-num{
    font-size: 32px;
    font-weight: bold
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/views/system/index/page.vue"
}
</vue-filename-injector>
