<template>
    <div class="field_wrap">
        <div class="mu-text-field">
            <div class="mu-text-field-content">
                <div class="mu-text-field-label" :class="{'float':!isEdit&&!thisVal}">{{label}}</div>
                    <input :type="type" v-model="thisVal" autocomplete="username" :tabindex="tabindex"  class="mu-text-field-input" @focus="focusHandle" @blur="blurHandle">
                    <div>
                        <hr class="mu-text-field-line">
                        <hr class="mu-text-field-focus-line" :class="{'focus':isEdit}">
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
// import '@/assets/scss/mu-field.scss'
export default {
  props: {
    label: String,
    value: String,
    type: {
      type: String,
      default: 'text'
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      isEdit: false,
      thisVal: this.value
    }
  },
  methods: {
    focusHandle () {
      this.isEdit = true
    },
    blurHandle () {
      this.isEdit = false
    }
  },
  watch: {
    // 监听prop传的value，如果父级有变化了，将子组件的myValue也跟着变，达到父变子变的效果
    value (newVal) {
      this.thisVal = newVal
    },
    // 监听myValue，如果子组件中的内容变化了，通知父级组件，将新的值告诉父级组件，我更新了，父级组件接受到值后页就跟着变了
    // 参考官网：https://cn.vuejs.org/v2/guide/components-custom-events.html#%E8%87%AA%E5%AE%9A%E4%B9%89%E7%BB%84%E4%BB%B6%E7%9A%84-v-model
    thisVal (newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss">
.field_wrap {
    position: relative;
    .mu-text-field {
        width: 100%;
        min-height: 72px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
        display: inline-block;
        position: relative;
        margin-bottom: 8px;
        .mu-text-field-content {
            display: block;
            height: 100%;
            padding-top: 28px;
            padding-bottom: 12px;
            .mu-text-field-label {
                top: 8px;
                position: absolute;
                line-height: 20px;
                transition: all .45s cubic-bezier(.23,1,.32,1);
                z-index: 1;
                cursor: text;
                transform: translateZ(0) scale(.75);
                transform-origin: left top;
                user-select: none;
                pointer-events: none;
                backface-visibility: hidden;
            }
            .float{
                    color: rgba(0, 0, 0, 0.38);
                    transform: translate3d(0,28px,0) scale(1);
                }
            .mu-text-field-input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                outline: none;
                border: none;
                background: none;
                border-radius: 0 0 0 0;
                box-shadow: none;
                display: block;
                padding: 0;
                margin: 0;
                width: 100%;
                height: 32px;
                font-style: inherit;
                font-variant: inherit;
                font-weight: inherit;
                font-stretch: inherit;
                font-size: inherit;
                color: rgba(0,0,0,.87);
                font-family: inherit;
                position: relative;
            }
            .mu-text-field-line {
                margin: 0;
                height: 1px;
                border: none;
                background-color: rgba(0, 0, 0, 0.4);
                left: 0;
                right: 0;
                position: absolute;
            }
            .mu-text-field-focus-line {
                margin: 0;
                height: 2px;
                border: none;
                background-color: #2878ff;
                position: absolute;
                left: 0;
                right: 0;
                margin-top: -1px;
                transform: scaleX(0);
                transition: transform .45s cubic-bezier(.23,1,.32,1);

            }
            .focus{
                transform: scaleX(1);
            }
        }
    }

}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/views/system/login/text-field.vue"
}
</vue-filename-injector>
