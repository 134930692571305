var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _vm.isShowLogo
        ? _c("div", { staticClass: "logo-container" }, [
            _c("div", { staticClass: "name" }, [_vm._v("人民云视")]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "main-container" }, [
        _c(
          "div",
          { staticClass: "banner-container" },
          [
            _c(
              "el-carousel",
              { attrs: { height: "587px", interval: 10000 } },
              _vm._l(this.banners, function (item) {
                return _c(
                  "el-carousel-item",
                  { key: item },
                  [
                    _c("el-image", {
                      staticStyle: { width: "440px", height: "587px" },
                      attrs: { src: item, fit: "contain" },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm.MFAform
          ? _c("div", { staticClass: "form-container" }, [
              _c("div", { staticClass: "title" }, [_vm._v("登录")]),
              _vm._m(0),
              _c(
                "div",
                { staticClass: "mfa-container-middle" },
                [
                  _c("text-field", {
                    attrs: { label: "动态码", type: "text" },
                    model: {
                      value: _vm.dynamicode,
                      callback: function ($$v) {
                        _vm.dynamicode = $$v
                      },
                      expression: "dynamicode",
                    },
                  }),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.machinecode,
                        callback: function ($$v) {
                          _vm.machinecode = $$v
                        },
                        expression: "machinecode",
                      },
                    },
                    [_vm._v("记住这台机器，7天内无需再次验证")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "btn-container" }, [
                _c(
                  "div",
                  { staticClass: "login-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.logining },
                        on: { click: _vm.MFAformSubmitHandle },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "forget-container-dynamicode",
                  staticStyle: { "padding-top": "0px" },
                },
                [
                  _c("p", [
                    _vm._v(" 如无法提供动态码，您可选择 "),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#1E89E7", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.service()
                          },
                        },
                      },
                      [_vm._v("联系客服")]
                    ),
                    _vm._v("解绑后继续操作 "),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm.nextform
          ? _c(
              "div",
              { staticClass: "form-container" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("登录")]),
                _c("text-field", {
                  attrs: { label: "用户名" },
                  model: {
                    value: _vm.dataForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "username", $$v)
                    },
                    expression: "dataForm.username",
                  },
                }),
                _c("text-field", {
                  attrs: { label: "密码", type: "password" },
                  model: {
                    value: _vm.spassword,
                    callback: function ($$v) {
                      _vm.spassword = $$v
                    },
                    expression: "spassword",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "verfy-container" },
                  [
                    _c("text-field", {
                      attrs: { label: "验证码" },
                      model: {
                        value: _vm.dataForm.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "code", $$v)
                        },
                        expression: "dataForm.code",
                      },
                    }),
                    _c("img", {
                      attrs: { src: _vm.captchaPath },
                      on: {
                        click: function ($event) {
                          return _vm.getCaptcha()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "btn-container" }, [
                  _c(
                    "div",
                    { staticClass: "login-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.logining },
                          on: { click: _vm.nextFormSubmitHandle },
                        },
                        [_vm._v("登录")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", {
                  staticClass: "forget-container",
                  staticStyle: { "padding-top": "0px" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.loginform
          ? _c(
              "div",
              { staticClass: "form-container" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("登录")]),
                _c("div", { staticClass: "verfy-container" }, [
                  _c("span", { staticClass: "login-phone" }, [
                    _vm._v("手机号"),
                  ]),
                  _c("div", { staticClass: "login-input-container" }, [
                    _c("span", [_vm._v(_vm._s(_vm.phone))]),
                    _c(
                      "div",
                      { staticClass: "login-getCode" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-mobile-phone",
                              size: "mini",
                              type: "primary",
                              disabled: (_vm.disabled = !_vm.show),
                            },
                            on: { click: _vm.send },
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.show,
                                    expression: "show",
                                  },
                                ],
                              },
                              [_vm._v("获取验证码")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.show,
                                    expression: "!show",
                                  },
                                ],
                                staticClass: "count",
                              },
                              [_vm._v(_vm._s(_vm.count) + " s")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("text-field", {
                  attrs: { label: "验证码", type: "text" },
                  model: {
                    value: _vm.phonecode,
                    callback: function ($$v) {
                      _vm.phonecode = $$v
                    },
                    expression: "phonecode",
                  },
                }),
                _c("div", { staticClass: "btn-container" }, [
                  _c(
                    "div",
                    { staticClass: "login-btn login-btn2" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.logining },
                          on: { click: _vm.loginFormSubmitHandle },
                        },
                        [_vm._v("登录")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.isShowCopyright
        ? _c("div", { staticClass: "copy-right-container" }, [
            _c("i", {
              staticClass: "el-icon-caret-left",
              staticStyle: { "font-size": "12px" },
            }),
            _c("i", {
              staticClass: "el-icon-caret-left",
              staticStyle: { "font-size": "16px" },
            }),
            _c("div", [_vm._v("©️ 2019 人民在线 版权所有")]),
            _c("i", {
              staticClass: "el-icon-caret-right",
              staticStyle: { "font-size": "16px" },
            }),
            _c("i", {
              staticClass: "el-icon-caret-right",
              staticStyle: { "font-size": "12px" },
            }),
          ])
        : _vm._e(),
      _vm.passworddia
        ? _c("changeOrForgetsetting", { ref: "changeOrForgetsetting" })
        : _vm._e(),
      _vm.serviceDia ? _c("serviceDia", { ref: "serviceDias" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mfa-container-top" }, [
      _c("p", [_vm._v("账号保护已开启，请根据提示完成以下操作")]),
      _c("span", [
        _vm._v(
          "请在手机中打开Google Authenticator/谷歌动态口令，请输入6位动态码"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }