var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d2-container-ghost-bs" }, [
    _vm.$slots.header
      ? _c(
          "div",
          { ref: "header", staticClass: "d2-container-ghost-bs__header" },
          [_vm._t("header")],
          2
        )
      : _vm._e(),
    _c("div", { ref: "wrapper", staticClass: "d2-container-ghost-bs__body" }, [
      _c("div", [_vm._t("default")], 2),
    ]),
    _vm.$slots.footer
      ? _c(
          "div",
          { ref: "footer", staticClass: "d2-container-ghost-bs__footer" },
          [_vm._t("footer")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }