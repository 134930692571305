// 菜单 顶栏
export default [{
  path: '/index',
  title: '数据增长',
  icon: ''
},
{
  path: '/OM',
  title: '组织管理',
  icon: ''
},
{
  path: '/channel-list',
  title: '频道管理',
  icon: ''
},

{
  path: '/operate-tools',
  title: '运营工具',
  icon: ''
},
{
  title: '功能插件',
  path: '/plugin-detial-list',
  icon: ''
},
{
  title: '融媒大脑',
  path: '/grid-management',
  icon: ''
}
]
