<template>
<el-dialog :visible.sync="diastatus" width="450px" >
  <div class="la-dialog">
    <div class="top">客服电话</div>
    <!-- <div class="phonenum">18888888888</div> -->
    <div class="phonenum">15566874621</div>
  </div>
  <div class="dialog-btns">
        <el-button  type="primary"  @click="diastatus = false">取消</el-button>
        <!-- <el-button type="primary" @click="changeFromSubmit('ruleForm')">确定</el-button> -->
    </div>
</el-dialog>
</template>

<script>
export default {
  data () {
    return {
      diastatus: true
    }
  },
  props: {
  },
  methods: {
    init () {
      this.diastatus = true
    }
  }
}
</script>

<style lang="scss">
.la-dialog{
    margin-top: 20px;
    .top{
        width: 70%;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }
    .phonenum{
        width: 70%;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
    }
}
.dialog-btns{
    margin-top: 10px;
    text-align: center;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/views/system/login/service-dia.vue"
}
</vue-filename-injector>
