var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d2-multiple-page-control-group", attrs: { flex: "" } },
    [
      _c(
        "div",
        {
          staticClass: "d2-multiple-page-control-content",
          attrs: { "flex-box": "1" },
        },
        [
          _c(
            "div",
            { staticClass: "d2-multiple-page-control-content-inner" },
            [
              _c(
                "d2-contextmenu",
                {
                  attrs: {
                    visible: _vm.contextmenuFlag,
                    x: _vm.contentmenuX,
                    y: _vm.contentmenuY,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.contextmenuFlag = $event
                    },
                  },
                },
                [
                  _c("d2-contextmenu-list", {
                    attrs: {
                      menulist:
                        _vm.tagName === "/index"
                          ? _vm.contextmenuListIndex
                          : _vm.contextmenuList,
                    },
                    on: { rowClick: _vm.contextmenuClick },
                  }),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  staticClass: "d2-multiple-page-control d2-multiple-page-sort",
                  attrs: { value: _vm.current, type: "card", closable: true },
                  on: {
                    "tab-click": _vm.handleClick,
                    edit: _vm.handleTabsEdit,
                  },
                  nativeOn: {
                    contextmenu: function ($event) {
                      return _vm.handleContextmenu.apply(null, arguments)
                    },
                  },
                },
                _vm._l(_vm.opened, function (page) {
                  return _c("el-tab-pane", {
                    key: page.fullPath,
                    attrs: {
                      label: page.meta.title || "未命名",
                      name: page.fullPath,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }