<template>
  <el-dropdown size="small" class="d2-mr">
    <span class="btn-text">你好{{ username }}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="logOff">
        <d2-icon name="power-off" class="d2-mr-5" />
        退出
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import util from '@/d2admin/libs/util.js'
import { MessageBox } from 'element-ui'
export default {
  data () {
    return {
      username: util.cookies.get('uuid')
    }
  },
  computed: {
    ...mapState('d2admin/user', [
      'info'
    ])
  },
  methods: {
    ...mapActions('d2admin/account', [
      'logout'
    ]),
    /**
     * @description 登出
     */
    logOff () {
      MessageBox.confirm('确定要退出当前用户吗', '退出用户', {
        type: 'warning'
      }).then(() => {
        this.$http.put('/admin/token/logOut')
          .then(({ data: res }) => {

            if (res.data === true) {
              this.$http.post('auth/token/logout').then(({ data: response }) => {
                if (response.code !== 0) {
                  return this.$message.error(response.message)
                }
                util.cookies.remove('token')
                this.logout({
                  confirm: true
                })
              }).catch(() => { })

            } else {
              this.$message.error('登出失败')
            }
          })
      }).catch(() => {

      })

    }
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>
