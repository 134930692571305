var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("d2-container", { staticClass: "page" }, [
    _c(
      "div",
      { staticStyle: { margin: "20px 20%" } },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [_c("el-col", { attrs: { span: 24 } }, [_vm._v("LAPUTA 整体增长")])],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { staticClass: "grid-content", attrs: { span: 11 } }, [
              _c("div", { staticStyle: { padding: "30px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "grid-content-top",
                    staticStyle: {
                      "background-color": "#0091fe",
                      "margin-top": "5px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../../module/demo/img/组 3.png"),
                        width: "100%",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c("div", [
                      _c("span", { staticStyle: { color: "#333333" } }, [
                        _vm._v("频道曝光次数/人气值"),
                      ]),
                    ]),
                    _c("countTo", {
                      staticClass: "grid-content-num",
                      attrs: {
                        startVal: 0,
                        endVal: this.consoleOverAllVO.popularityAllBefore,
                        duration: 3000,
                      },
                    }),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#a6a6bf",
                            "margin-top": "10px",
                            "font-size": "14px",
                          },
                        },
                        [
                          _vm._v("近7日新增"),
                          _c(
                            "span",
                            {
                              staticStyle: { "margin-left": "5px" },
                              style: {
                                color:
                                  this.consoleOverAllVO.popularityAllBefore ===
                                  0
                                    ? "#ff0000"
                                    : "#00cc0b",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(this.consoleOverAllVO.popularity7Before)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("el-col", { staticClass: "grid-content", attrs: { span: 11 } }, [
              _c("div", { staticStyle: { padding: "30px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "grid-content-top",
                    staticStyle: {
                      "background-color": "#00cc00",
                      "margin-top": "5px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../../module/demo/img/组 4.png"),
                        width: "100%",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c("div", [
                      _c("span", { staticStyle: { color: "#333333" } }, [
                        _vm._v("频道用户数/独立用户"),
                      ]),
                    ]),
                    _c("countTo", {
                      staticClass: "grid-content-num",
                      attrs: {
                        startVal: 0,
                        endVal: this.consoleOverAllVO.membersAllBefore,
                        duration: 3000,
                      },
                    }),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#a6a6bf",
                            "margin-top": "10px",
                            "font-size": "14px",
                          },
                        },
                        [
                          _vm._v("近7日新增"),
                          _c(
                            "span",
                            {
                              staticStyle: { "margin-left": "5px" },
                              style: {
                                color:
                                  this.consoleOverAllVO.membersAllBefore === 0
                                    ? "#ff0000"
                                    : "#00cc0b",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(this.consoleOverAllVO.members7Before)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { staticClass: "grid-content", attrs: { span: 11 } }, [
              _c("div", { staticStyle: { padding: "30px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "grid-content-top",
                    staticStyle: {
                      "background-color": "#ff9900",
                      "margin-top": "5px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../../module/demo/img/组 5.png"),
                        width: "100%",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c("div", [
                      _c("span", { staticStyle: { color: "#333333" } }, [
                        _vm._v("新闻浏览数"),
                      ]),
                    ]),
                    _c("countTo", {
                      staticClass: "grid-content-num",
                      attrs: {
                        startVal: 0,
                        endVal: this.consoleOverAllVO.readingAllBefore,
                        duration: 3000,
                      },
                    }),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#a6a6bf",
                            "margin-top": "10px",
                            "font-size": "14px",
                          },
                        },
                        [
                          _vm._v("近7日新增"),
                          _c(
                            "span",
                            {
                              staticStyle: { "margin-left": "5px" },
                              style: {
                                color:
                                  this.consoleOverAllVO.readingAllBefore === 0
                                    ? "#ff0000"
                                    : "#00cc0b",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(this.consoleOverAllVO.reading7Before)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("el-col", { staticClass: "grid-content", attrs: { span: 11 } }, [
              _c("div", { staticStyle: { padding: "30px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "grid-content-top",
                    staticStyle: { "margin-top": "5px" },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../../module/demo/img/组 6.png"),
                        width: "100%",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c("div", [
                      _c("span", { staticStyle: { color: "#333333" } }, [
                        _vm._v("新闻发稿数"),
                      ]),
                    ]),
                    _c("countTo", {
                      staticClass: "grid-content-num",
                      attrs: {
                        startVal: 0,
                        endVal: this.consoleOverAllVO.worksAllBefore,
                        duration: 3000,
                      },
                    }),
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#a6a6bf",
                            "margin-top": "10px",
                            "font-size": "14px",
                          },
                        },
                        [
                          _vm._v("近7日新增"),
                          _c(
                            "span",
                            {
                              staticStyle: { "margin-left": "5px" },
                              style: {
                                color:
                                  this.consoleOverAllVO.worksAllBefore === 0
                                    ? "#ff0000"
                                    : "#00cc0b",
                              },
                            },
                            [_vm._v(_vm._s(this.consoleOverAllVO.works7Before))]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "column-container" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [_c("el-col", { attrs: { span: 24 } }, [_vm._v("LAPUTA 频道增长")])],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              data: this.consoleOverAllVO.channelOverList,
              "default-sort": { prop: "popularityAllBefore" },
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "", width: "220", "min-width": "140" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "column-container-scope" }, [
                        _vm._v(_vm._s(scope.row.channelName)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "popularityAllBefore",
                label: "频道曝光次数/人气值",
                "cell-class-name": "cell-title",
                sortable: "",
                width: "220",
                "min-width": "140",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "bold",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.popularityAllBefore)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#a6a6bf",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "近7日 +" + _vm._s(scope.row.popularity7Before)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "频道用户数/独立用户",
                prop: "membersAllBefore",
                sortable: "",
                width: "220",
                "min-width": "140",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v(_vm._s(scope.row.membersAllBefore))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#a6a6bf",
                          },
                        },
                        [_vm._v("+" + _vm._s(scope.row.members7Before))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "readingAllBefore",
                label: "新闻浏览数",
                sortable: "",
                formatter: _vm.formatter,
                width: "220",
                "min-width": "140",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v(_vm._s(scope.row.readingAllBefore))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#a6a6bf",
                          },
                        },
                        [_vm._v("+" + _vm._s(scope.row.reading7Before))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "worksAllBefore",
                label: "新闻发稿数",
                sortable: "",
                width: "272",
                "min-width": "140",
                formatter: _vm.formatter,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v(_vm._s(scope.row.worksAllBefore))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#a6a6bf",
                          },
                        },
                        [_vm._v("+" + _vm._s(scope.row.works7Before))]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }