<template>
  <div class="login-page">
    <div class="logo-container" v-if="isShowLogo">
      <!-- <img class="logo" :src="logo"/> -->
      <div class="name">人民云视</div>
    </div>
    <div class="main-container">
      <div class="banner-container">
        <el-carousel height="587px" :interval="10000">
          <el-carousel-item v-for="item in this.banners" :key="item">
            <el-image style="width: 440px; height: 587px" :src="item" fit="contain"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-if="MFAform" class="form-container">
        <div class="title">登录</div>
        <div class="mfa-container-top">
          <p>账号保护已开启，请根据提示完成以下操作</p>
          <span>请在手机中打开Google Authenticator/谷歌动态口令，请输入6位动态码</span>
        </div>
        <div class="mfa-container-middle">
          <text-field label="动态码" type="text" v-model="dynamicode"></text-field>
          <el-checkbox v-model="machinecode">记住这台机器，7天内无需再次验证</el-checkbox>
        </div>
        <div class="btn-container">
          <div class="login-btn">
            <el-button type="primary" :loading="logining" @click="MFAformSubmitHandle">确定</el-button>
          </div>
        </div>
        <div class="forget-container-dynamicode" style="padding-top:0px">
          <p>
            如无法提供动态码，您可选择
            <span style="color:#1E89E7;cursor: pointer;" @click="service()">联系客服</span>解绑后继续操作
          </p>
        </div>
      </div>
      <div class="form-container" v-if="nextform">
        <div class="title">登录</div>
        <!-- <text-field label="机构ID" v-model="dataForm.tenant_id"></text-field> -->
        <text-field label="用户名" v-model="dataForm.username"></text-field>
        <text-field label="密码" type="password" v-model="spassword"></text-field>
        <div class="verfy-container">
          <text-field label="验证码" v-model="dataForm.code"></text-field>
          <img :src="captchaPath" @click="getCaptcha()" />
        </div>
        <div class="btn-container">
          <div class="login-btn">
            <el-button type="primary" :loading="logining" @click="nextFormSubmitHandle">登录</el-button>
          </div>
        </div>
        <div class="forget-container" style="padding-top:0px">
          <!-- <p @click="forgetPassword">忘记密码</p> -->
        </div>
      </div>
      <div class="form-container" v-if="loginform">
        <div class="title">登录</div>
        <div class="verfy-container">
          <span class="login-phone">手机号</span>
          <div class="login-input-container">
            <span>{{phone}}</span>
            <div class="login-getCode">
              <el-button
                icon="el-icon-mobile-phone"
                @click="send"
                size="mini"
                type="primary"
                :disabled="disabled=!show"
              >
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{count}} s</span>
              </el-button>
            </div>
          </div>
        </div>
        <text-field label="验证码" type="text" v-model="phonecode"></text-field>
        <div class="btn-container">
          <div class="login-btn login-btn2">
            <el-button type="primary" :loading="logining" @click="loginFormSubmitHandle">登录</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-right-container" v-if="isShowCopyright">
      <i class="el-icon-caret-left" style="font-size:12px;" />
      <i class="el-icon-caret-left" style="font-size:16px;" />
      <div>©️ 2019 人民在线 版权所有</div>
      <i class="el-icon-caret-right" style="font-size:16px;" />
      <i class="el-icon-caret-right" style="font-size:12px;" />
    </div>
    <changeOrForgetsetting v-if="passworddia" ref="changeOrForgetsetting"></changeOrForgetsetting>
    <serviceDia v-if="serviceDia" ref="serviceDias"></serviceDia>
    <!-- <Forgetsetting v-if="forgetpassworddia" ref="Forgetsetting"></Forgetsetting> -->
  </div>
</template>

<script>
import textField from "./text-field";
// import { getUUID, encryPwd } from '@/utils'
import debounce from "lodash/debounce";
// import Cookies from 'js-cookie'
import banner1 from "./image/banner1.png";
import banner2 from "./image/banner2.png";
import banner3 from "./image/banner3.png";
import banner4 from "./image/banner4.png";
import CryptoJS from "crypto-js";
import util from "@/d2admin/libs/util.js";
// import dayjs from 'dayjs'
import { mapActions } from "vuex";
import localeMixin from "@/locales/mixin.js";
// import logo from '@/assets/img/yunshi.png'
import changeOrForgetsetting from "./change-forget-passwordsetting";
import serviceDia from "./service-dia";
// import Forgetsetting from './forget-password'
export default {
  mixins: [localeMixin],
  data() {
    return {
      captchaPath: "",
      spassword: "",
      // logo: logo,
      logining: false,
      nextform: true,
      loginform: false,
      MFAform: false,
      machinecode: false,
      dynamicode: "",
      serviceDia: false,
      show: true,
      count: "",
      timer: null,
      passworddia: false,
      forgetpassworddia: false,
      dataForm: {
        username: "",
        password: "",
        randomStr: "",
        code: "",
        grant_type: "password",
        scope: "server",
        tenant_id: 10
      },
      phone: "",
      phonecode: "",
      authCode: "",
      defaultBannerList: [],
      banners: [],
      isShowLogo: true,
      isShowCopyright: true
    };
  },
  components: {
    textField,
    changeOrForgetsetting,
    serviceDia
    // Forgetsetting
  },
  methods: {
    ...mapActions("d2admin/account", ["login"]),
    encryPwd(pwd) {
      var key = CryptoJS.enc.Latin1.parse("abcdefgh12345678");
      var iv = key;
      var encrypted = CryptoJS.AES.encrypt(pwd, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      });
      var newPass = encrypted.toString();
      // var src = CryptoJS.enc.Utf8.parse(newPass)
      // var base64string = CryptoJS.enc.Base64.stringify(src)
      // console.log(base64string)
      return newPass;
    },
    showLogo() {
      var h = (window.innerHeight - 587) / 2 - 50;
      this.isShowLogo = h > 0;
    },
    showCopyright() {
      var h = (window.innerHeight - 587) / 2 - 55;
      this.isShowCopyright = h > 0;
    },
    // 获取验证码
    getCaptcha() {
      this.dataForm.randomStr = this.getUUID();
      this.captchaPath =
        process.env.VUE_APP_API + `code?randomStr=${this.dataForm.randomStr}`;
    },
    getUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        return (c === "x"
          ? (Math.random() * 16) | 0
          : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    setBannerCache(banners) {
      // if (!window.localStorage) {
      //   return
      // }
      // window.localStorage['login_banners'] = banners
    },
    getBannerCache() {
      if (!window.localStorage) {
        return null;
      }
      // var banners = window.localStorage['login_banners']
      // if (!banners) {
      //   return null
      // }
      // return banners.split(',')
    },
    service() {
      this.serviceDia = true;
      this.$nextTick(() => {
        this.$refs.serviceDias.init();
      });
    },
    // 1.3.1 登录发送短信验证
    send() {
      this.$http
        .get(
          "/admin/mobile/web?authCode=" +
            this.authCode +
            "&tenant_id=" +
            this.dataForm.tenant_id,
          {
            headers: {
              Authorization: "Basic d2ViOnl1bnNoaS13ZWItMTIzNDU2"
            }
          }
        )
        .then(response => {
          if (response.data.code === 0) {
            this.$message.success("验证码已发出，请注意查收");
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            return this.$message.error(response.data.message);
          }
        });
    },
    forgetPassword() {
      this.forgetpassworddia = true;
      this.logins(false);
      this.$nextTick(() => {
        this.$refs.Forgetsetting.init();
      });
    },
    // 表单提交
    nextFormSubmitHandle: debounce(function() {
      // this.$http.get('console/index/getOverAll')
      if (!this.dataForm.tenant_id || this.dataForm.tenant_id.length === 0) {
        return this.$message.error("机构ID不能为空");
      }
      if (!this.dataForm.username || this.dataForm.username.length === 0) {
        return this.$message.error("用户名不能为空");
      }
      if (!this.spassword || this.spassword === 0) {
        return this.$message.error("密码不能为空");
      }
      if (!this.dataForm.code) {
        return this.$message.error("验证码不能为空");
      }

      // 加密密码
      this.dataForm.password = this.encryPwd(this.spassword);
      this.logining = true;
      this.$http
        .post("/auth/oauth/token", null, {
          params: this.dataForm,
          headers: {
            isToken: false,
            tenant_id: this.dataForm.tenant_id,
            Authorization: "Basic d2ViOnl1bnNoaS13ZWItMTIzNDU2"
          }
        })
        .then(({ data: response }) => {
          this.logining = false;
          // 判断非双因子
          if (!response.access_token) {
            if (response.access_token === null) {
              var sendForm = {
                username: this.dataForm.username,
                password: this.dataForm.password,
                grant_type: this.dataForm.grant_type,
                scope: this.dataForm.scope,
                tenant_id: this.dataForm.tenant_id,
                authCode: response.authCode
              };
              if (response.nextStap === "0") {
                this.passworddia = true;
                this.$nextTick(() => {
                  this.logins(false);
                  this.$refs.changeOrForgetsetting.itemtype = "inittype";
                  this.$refs.changeOrForgetsetting.titletype = "inittype";
                  this.$refs.changeOrForgetsetting.ruleForm.organ = this.dataForm.tenant_id;
                  this.$refs.changeOrForgetsetting.ruleForm.phone =
                    response.phone;
                  this.$refs.changeOrForgetsetting.ruleForm.authCode =
                    response.authCode;
                  this.$refs.changeOrForgetsetting.sendForm = sendForm;
                  this.$refs.changeOrForgetsetting.init();
                });
                return;
              } else if (response.nextStap === "2") {
                this.passworddia = true;
                this.logins(false);
                this.$nextTick(() => {
                  this.$refs.changeOrForgetsetting.itemtype = "timeOut";
                  this.$refs.changeOrForgetsetting.titletype = "timeOut";
                  this.$refs.changeOrForgetsetting.ruleForm.organ = this.dataForm.tenant_id;
                  this.$refs.changeOrForgetsetting.ruleForm.phone =
                    response.phone;
                  this.$refs.changeOrForgetsetting.ruleForm.authCode =
                    response.authCode;
                  this.$refs.changeOrForgetsetting.sendForm = sendForm;
                  this.$refs.changeOrForgetsetting.init();
                });
                return;
              } else if (response.nextStap === "4") {
                this.nextform = false;
                this.loginform = false;
                this.authCode = response.authCode;
                this.MFAform = true;
                return;
              }
              this.nextform = false;
              this.loginform = true;
              this.phone = response.phone;
              this.authCode = response.authCode;
              return;
            }
            if (response.code == 1) {
                this.$confirm('此账户已在其他地址登录，是否强制下线他人下线并登录', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$http.post('auth/token/logout', null,{
                    headers: {
                      Authorization: `Bearer ${response.message}`
                    }
                  }).then(({ data: res }) => {
                    if (res.code !== 0) {
                      return this.$message.error(res.message)
                    }
                    this.nextFormSubmitHandle()
                  }).catch(() => { })
                })
                return
              }
            this.getCaptcha();
            return this.$message.error(response.message);
          } else {
            //  存储token
            util.cookies.set("token", response.access_token);
            // 页面显示当前用户名称
            util.cookies.set("uuid", this.dataForm.username);
            // 跳转
            this.$router.replace(this.$route.query.redirect || "/");
            // Cookies.set("token", response.access_token);
            // Cookies.set("tenant_id", this.dataForm.tenant_id);
            // this.$router.push({ path: 'index' })
            // 加载灰度状态再进行后续登录处理
            // const grayReq = this.$apis.getGray(this);
            // grayReq
            //   .then(({ data: response }) => {
            //     if (response.code !== 0) {
            //       this.$message.error("灰度状态读取失败");
            //     }
            //     // 保存灰度设置
            //     this.$store.state.gray = response.data.Graystatus;
            //     this.$router.replace({ name: "home" });
            //   })
            //   .catch(() => {
            //     this.$store.state.gray = 0;
            //     this.$router.replace({ name: "home" });
            //   });
          }
        });
    }, 1000),
    // 1.3.1 第二部分登录验证
    loginFormSubmitHandle: debounce(function() {
      if (this.phonecode === "") return this.$message.error("验证码不能为空");
      var loginparams = {
        username: this.dataForm.username,
        password: this.dataForm.password,
        grant_type: this.dataForm.grant_type,
        scope: this.dataForm.scope,
        tenant_id: this.dataForm.tenant_id,
        authCode: this.authCode,
        code: this.phonecode
      };
      this.logining = true;
      this.$http
        .post("/auth/oauth/token", null, {
          params: loginparams,
          headers: {
            isToken: false,
            tenant_id: this.dataForm.tenant_id,
            Authorization: "Basic d2ViOnl1bnNoaS13ZWItMTIzNDU2"
          }
        })
        .then(({ data: response }) => {
          this.logining = false;
          if (!response.access_token) {
            return this.$message.error(response.message);
          } else {
            this.logins(false);
            util.cookies.set("token", response.access_token);
            // 页面显示当前用户名称
            util.cookies.set("uuid", this.dataForm.username);
            // 跳转
            this.$router.replace(this.$route.query.redirect || "/");
          }
        });
    }, 1000),
    // V1.3.4 MFA
    MFAformSubmitHandle: debounce(function() {
      console.log(this.machinecode, this.dynamicode);
      if (this.dynamicode === "") return this.$message.error("动态码不能为空");
      var loginparams = {
        username: this.dataForm.username,
        password: this.dataForm.password,
        grant_type: this.dataForm.grant_type,
        scope: this.dataForm.scope,
        tenant_id: this.dataForm.tenant_id,
        authCode: this.authCode,
        needRememberMFA: this.machinecode,
        code: this.dynamicode
      };
      this.logining = true;
      this.$http
        .post("/auth/oauth/token", null, {
          params: loginparams,
          headers: {
            isToken: false,
            tenant_id: this.dataForm.tenant_id,
            Authorization: "Basic d2ViOnl1bnNoaS13ZWItMTIzNDU2"
          }
        })
        .then(({ data: response }) => {
          this.logining = false;
          if (response.access_token) {
            this.logins(false);
            util.cookies.set("token", response.access_token);
            // 页面显示当前用户名称
            util.cookies.set("uuid", this.dataForm.username);
            // 跳转
            this.$router.replace(this.$route.query.redirect || "/");
          } else {
            // if (res.code === 13401) {
            //   return this.$message.error('验证码错误')
            // }
            return this.$message.error(response.message);
          }
        });
    }, 1000),
    logins(val) {
      const This = this;
      document.onkeydown = function(event) {
        const e = event || window.event;
        if (e && e.keyCode === 13 && val) {
          if (This.nextform) {
            This.nextFormSubmitHandle();
            return;
          }
          if (This.loginform) {
            This.loginFormSubmitHandle();
            return;
          }
          if (This.MFAform) {
            This.MFAformSubmitHandle();
          }
        }
      };
    }
  },
  beforeDestroy() {
    this.logins(false);
  },
  mounted() {
    this.logins(true);
    window.onresize = () => {
      this.showLogo();
      this.showCopyright();
    };
    this.getCaptcha();
    this.defaultBannerList.push(banner1);
    this.defaultBannerList.push(banner2);
    this.defaultBannerList.push(banner3);
    this.defaultBannerList.push(banner4);
    // 先使用默认初始化
    var tmpBanners = this.defaultBannerList;
    // 读取缓存初始化
    var cachedBanners = this.getBannerCache();
    if (cachedBanners) {
      tmpBanners = cachedBanners;
    }
    this.banners = tmpBanners;
    // 读取接口读取新数据,并更新缓存
    // this.$http.get('/sys/homeBanners').then(({ data: response }) => {
    //   if (response.code !== 0 || !response.data) {
    //     return
    //   }
    //   this.setBannerCache(response.data.banners)
    //   this.banners = this.getBannerCache()
    // })
  }
};
</script>

<style lang="scss" >
.login-page {
  // background-image:url('~@/assets/img/login-back.png');
  background-repeat: repeat;
  font-family: "PingFang SC";
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  .main-container {
    height: 587px;
    display: flex;
    .banner-container {
      width: 440px;
      height: 100%;
      overflow: hidden;
      .el-carousel {
        overflow-y: hidden;
      }
    }
    .form-container {
      width: 400px;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 3px 3px 6px rgba(30, 54, 85, 0.1);
      padding: 0 90px;
      .mfa-container-top {
        padding-top: 90px;
        width: 75%;
        margin: 0 auto;
        p {
          font-weight: 600;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
        }
        span {
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          font-size: 14px;
        }
      }
      .mfa-container-middle {
        width: 75%;
        margin: 0 auto;
      }
      .title {
        font-size: 38px;
        font-weight: bold;
        color: rgba(30, 137, 231, 1);
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
      }
      .verfy-container {
        display: flex;
        position: relative;
        .login-input-container {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: flex-end;
          border-bottom: solid 1px #7f7f7f;
          position: relative;
          span {
            padding-bottom: 5px;
          }
          .el-input__inner {
            border: none;
            padding: 0;
          }
        }
        img {
          margin-top: 10px;
          height: 100%;
          margin-left: 20px;
        }
        .field_wrap {
          flex-grow: 1;
        }
      }
      .btn-container {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 55px;
        .login-btn {
          width: 80%;
          display: flex;
          justify-content: center;
          .el-button {
            width: 100%;
            font-size: 24px;
          }
        }
      }
    }
  }
}
.logo-container {
  display: flex;
  position: absolute;
  top: 20px;
  left: 30px;
  .logo {
    width: 30px;
    height: 30px;
    
  }
  .name {
    margin-left: 14px;
    color: rgba(53, 53, 53, 1);
    font-weight: bold;
    font-size: 21px;
    font-family: PingFang SC;
  }
}
.copy-right-container {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 30px;
  align-items: center;
  color: #1e89e7;
  font-size: 14px;
}
.login-getCode {
  float: right;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 5px;
}
.login-phone {
  position: absolute;
  color: rgba(0, 0, 0, 0.38);
  bottom: 35px;
}
.login-btn2 {
  margin-top: 160px;
}
.forget-container {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  p {
    cursor: pointer;
    // padding: 10px 0
  }
}
.forget-containe:hover {
  color: #1e89e7;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/views/system/login/page.vue"
}
</vue-filename-injector>
